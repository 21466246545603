const sp = {
  faculties: [
    {
      name: "طب",
      id: "med",
      credit: -1,
      startYear: 1,
      endYear: 7
    },
    {
      name: "علوم",
      id: "sci",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "العلوم الفيزيائية",
          id: "phys",
          spname: "شعبة",
          specializations: [
            {
              name: "الفيزياء والرياضيات",
              id: "phymath",
              spname: "تخصص",
              specializations: [
                {
                  name: "علوم الحاسب",
                  id: "comp"
                },
                {
                  name: "الفيزياء",
                  id: "phys"
                },
                {
                  name: "الرياضيات",
                  id: "math"
                }
              ]
            },
            {
              name: "الفيزياء والكيمياء",
              id: "phychem",
              spname: "تخصص",
              specializations: [
                {
                  name: "الكيمياء",
                  id: "chem"
                }
              ]
            }
          ]
        },
        {
          name: "العلوم البيولوجية",
          id: "bio",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "الكيمياء وعلم الحيوان",
              id: "chemanim"
            },
            {
              name: "علم الحيوان",
              id: "anim"
            },
            {
              name: "الكيمياء والنبات",
              id: "chemplants",
              spname: "تخصص",
              specializations: [
                {
                  name: "الكيمياء والنبات",
                  id: "chemplants"
                },
                {
                  name: "النبات",
                  id: "plants"
                }
              ]
            },
            {
              name: "الكيمياء والميكروبيولوجيا",
              id: "chemmicro"
            }
          ]
        },
        {
          name: "العلوم الجيولوجية",
          id: "geo",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "الجيولوجيا",
              id: "geo"
            },
            {
              name: "الجيوفيزياء",
              id: "geophys"
            },
            {
              name: "الجيولوجيا والكيمياء",
              id: "geochem"
            }
          ]
        },
        {
          name: "العلوم البيئية",
          id: "envir"
        }
      ]
    },
    {
      name: "أثار",
      id: "arc",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "الآثار المصرية",
          id: "egyptian"
        },
        {
          name: "الآثار الإسلامية",
          id: "islamic"
        },
        {
          name: "ترميم الآثار",
          id: "restmonum"
        }
      ]
    },
    {
      name: "ألسن",
      id: "lang",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "اللغة",
      specializations: [
        {
          name: "الإنجليزية",
          id: "eng"
        },
        {
          name: "الفرنسية",
          id: "fre"
        },
        {
          name: "الأسبانية",
          id: "spa"
        },
        {
          name: "البرتغالية",
          id: "por"
        },
        {
          name: "الصينية",
          id: "chi"
        },
        {
          name: "اليابانية",
          id: "jap"
        },
        {
          name: "الكورية",
          id: "kor"
        },
        {
          name: "الروسية",
          id: "rus"
        }
      ]
    },
    {
      name: "أداب",
      id: "art",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "اللغة العربية وأدابها",
          id: "ara"
        },
        {
          name: "اللغة الانجليزية وادابها",
          id: "eng"
        },
        {
          name: "اللغة الفرنسية وادابها",
          id: "fre"
        },
        {
          name: "المكتبات والمعلومات",
          id: "lib"
        },
        {
          name: "علم النفس",
          id: "psy"
        },
        {
          name: "الاجتماع",
          id: "meet"
        },
        {
          name: "التاريخ",
          id: "his"
        },
        {
          name: "الجغرافيا",
          id: "geog",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "عامة",
              id: "genr"
            },
            {
              name: "المساحة والخرائط",
              id: "maps"
            },
            {
              name: "نظم المعلومات الجغرافية",
              id: "info"
            }
          ]
        },
        {
          name: "الاعلام",
          id: "media",
          spname: "شعبة",
          specializations: [
            {
              name: "الصحافة",
              id: "j"
            },
            {
              name: "العلاقات العامة والاعلام السياحي",
              id: "pr"
            }
          ]
        }
      ]
    },
    {
      name: "تربية",
      id: "edu",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "طفولة",
          id: "child"
        },
        {
          name: "أساسى",
          id: "b",
          years: 0,
          spname: "شعبة",
          specializations: [
            {
              name: "ادبي",
              id: "lit",
              years: 2,
              spname: "تخصص",
              specializations: [
                {
                  name: "اللغة العربية",
                  id: "ara"
                },
                {
                  name: "اللغة الإنجليزية",
                  id: "eng"
                },
                {
                  name: "دراسات الإجتماعية",
                  id: "social"
                }
              ]
            },
            {
              name: "علمي",
              id: "sci",
              years: 2,
              spname: "تخصص",
              specializations: [
                {
                  name: "رياضيات",
                  id: "math"
                },
                {
                  name: "علوم",
                  id: "sci"
                },
                {
                  name: "اللغة العربية",
                  id: "ara"
                },
                {
                  name: "اللغة الإنجليزية",
                  id: "eng"
                },
                {
                  name: "دراسات الإجتماعية",
                  id: "social"
                }
              ]
            }
          ]
        },
        {
          name: "عام",
          id: "g",
          years: 0,
          spname: "شعبة",
          specializations: [
            {
              name: "اللغة العربية",
              id: "ara"
            },
            {
              name: "بيولوجي وجيولوجي",
              id: "bio"
            },
            {
              name: "اللغة الإنجليزية",
              id: "eng"
            },
            {
              name: "اللغة الفرنسية",
              id: "fre"
            },
            {
              name: "جغرافيا",
              id: "geog"
            },
            {
              name: "تاريخ",
              id: "history"
            },
            {
              name: "رياضيات",
              id: "math"
            },
            {
              name: "الفيزياء والكيمياء",
              id: "phychem",
              years: 2,
              spname: "تخصص",
              specializations: [
                {
                  name: "كيمياء",
                  id: "chem"
                },
                {
                  name: "فيزياء",
                  id: "phy"
                }
              ]
            },
            {
              name: "علم النفس",
              id: "psy"
            }
          ]
        }
      ]
    },
    {
      name: "حقوق",
      id: "law",
      credit: 4,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "عام",
          id: "g"
        },
        {
          name: "اللغة الانجليزية",
          id: "eng"
        },
        {
          name: "الوافدين",
          id: "foreig"
        }
      ]
    },
    {
      name: "تجارة",
      id: "com",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "اللغة العربية",
          id: "ar",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "اقتصاد",
              id: "eco"
            },
            {
              name: "عامة",
              id: "general",
              spname: "تخصص",
              specializations: [
                {
                  name: "محاسبة",
                  id: "acc"
                },
                {
                  name: "ادارة الاعمال",
                  id: "bus"
                }
              ]
            }
          ]
        },
        {
          name: "اللغة الانجليزية",
          id: "en",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "شعبة عامة",
              id: "general",
              spname: "تخصص",
              specializations: [
                {
                  name: "محاسبة",
                  id: "acc"
                },
                {
                  name: "ادارة الاعمال",
                  id: "bus"
                },
                {
                  name: "اقتصاد",
                  id: "eco"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "هندسة",
      id: "eng",
      credit: -1,
      startYear: 0,
      endYear: 4,
      years: 1,
      spname: "قسم",
      specializations: [
        {
          name: "الهندسة المعمارية",
          id: "arch"
        },
        {
          name: "الهندسة المدنية",
          id: "civil"
        },
        {
          name: "الهندسة الكهربية",
          id: "elect",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "الالكترونيات والاتصالات",
              id: "comn"
            },
            {
              name: "تحكم وحاسبات",
              id: "cont"
            },
            {
              name: "القوى والالات الكهربية",
              id: "powr"
            }
          ]
        }
      ]
    },
    {
      name: "تمريض",
      id: "nursing",
      credit: -1,
      startYear: 1,
      endYear: 4
    },
    {
      name: "دار العلوم",
      id: "dar",
      credit: -1,
      startYear: 1,
      endYear: 4
    },
    {
      name: "هندسة الطاقة",
      id: "energy",
      credit: 4,
      startYear: 0,
      endYear: 4,
      years: 1,
      spname: "قسم",
      specializations: [
        {
          name: "هندسة القوى الميكانيكية",
          id: "mech"
        },
        {
          name: "هندسة القوي الكهربية",
          id: "netw"
        }
      ]
    },
    {
      name: "تربية نوعية",
      id: "spec",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "التربية الفنية",
          id: "art"
        },
        {
          name: "تكنولوجيا التعليم التربوي",
          id: "tech"
        },
        {
          name: "الاقتصاد المنزلى التربوى",
          id: "eco"
        },
        {
          name: "التربية الموسيقية",
          id: "music"
        },
        {
          name: "الإعـلام التربـوى",
          id: "media",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "صحافة واذاعة وتليفزيون",
              id: "jtv"
            },
            {
              name: "فنون مسرحية",
              id: "art"
            }
          ]
        }
      ]
    },
    {
      name: "طب البيطري",
      id: "vet",
      credit: -1,
      startYear: 1,
      endYear: 5
    },
    {
      name: "تربية الرياضية",
      id: "phy",
      credit: -1,
      startYear: 1,
      endYear: 4,
      spname: "اللائحة",
      specializations: [
        {
          name: "لائحة جديدة",
          id: "new",
          yearsonly: [1, 2]
        },
        {
          name: "لائحة قديمة",
          id: "old"
        }
      ]
    },
    {
      name: "خدمة الإجتماعية",
      id: "sworks",
      credit: -1,
      startYear: 1,
      endYear: 4
    },
    {
      name: "زراعة",
      id: "agr",
      credit: 4,
      startYear: 1,
      endYear: 4,
      spname: "قسم",
      specializations: [
        {
          name: "زراعة العام",
          id: "g",
          years: 2,
          spname: "شعبة",
          specializations: [
            {
              name: "انتاج نباتى",
              id: "plantproduct",
              spname: "تخصص",
              specializations: [
                {
                  name: "محاصيل",
                  id: "crop"
                },
                {
                  name: "بساتين",
                  id: "orchards"
                }
              ]
            },
            {
              name: "أراضى وموارد طبيعية",
              id: "agrnatural",
              spname: "تخصص",
              specializations: [
                {
                  name: "الاراضي والمياه",
                  id: "earthwater"
                },
                {
                  name: "البيئة والموارد الطبيعية",
                  id: "environsci"
                }
              ]
            },
            {
              name: "أغذية وألبان",
              id: "milk",
              spname: "تخصص",
              specializations: [
                {
                  name: "تكنولوجيا الألبان",
                  id: "dairytech"
                },
                {
                  name: "تكنولوجيا الأغذية",
                  id: "foodtech"
                }
              ]
            },
            {
              name: "انتاج حيوانى",
              id: "animalproduct",
              spname: "تخصص",
              specializations: [
                {
                  name: "انتاج حيوانى",
                  id: "animalproduct"
                },
                {
                  name: "إنتاج الدواجن",
                  id: "poultryproduct"
                }
              ]
            },
            {
              name: "وقاية النبات",
              id: "plantprotection",
              spname: "تخصص",
              specializations: [
                {
                  name: " حشرات",
                  id: "insects"
                },
                {
                  name: " مبيدات",
                  id: "pesticides"
                },
                {
                  name: " أمراض نبات",
                  id: "plantdis"
                }
              ]
            },
            {
              name: "تكنولوجيا حيوية",
              id: "biotechno"
            },
            {
              name: "زراعةاقتصادية واجتماعية",
              id: "economysocial",
              spname: "تخصص",
              specializations: [
                {
                  name: "الاقتصاد الزراع",
                  id: "agreconomy"
                },
                {
                  name: "الإرشاد الزراعي",
                  id: "agrextension"
                }
              ]
            }
          ]
        },
        {
          name: "هندسة الزراعية",
          id: "eng"
        }
      ]
    },
    {
      name: "مصايد وأسماك",
      id: "fish",
      credit: 4,
      startYear: 1,
      endYear: 4,
      spname: "البرنامج",
      specializations: [
        {
          name: "عام",
          id: "general"
        },
        {
          name: "الاستزراع المائي",
          id: "aquaculture"
        },
        {
          name: "المصايد الدخلية",
          id: "internal"
        }
      ]
    }
  ]
};

const grades = {
  credit: [
    "المستوي الاعدادي",
    "المستوي الاول",
    "المستوي الثاني",
    "المستوي الثالث",
    "المستوي الرابع",
    "المستوي الخامس",
    "المستوي السادس",
    "المستوي السابع"
  ],
  noncredit: [
    "الفرقة الاعدادي",
    "الفرقة الاولي",
    "الفرقة الثانية",
    "الفرقة الثالثة",
    "الفرقة الرابعة",
    "الفرقة الخامسة",
    "الفرقة السادسة",
    "الفرقة السابعة"
  ],
  numbers: [
    "اعدادي",
    "اولي",
    "ثانية",
    "ثالثة",
    "رابعة",
    "خامسة",
    "سادسة",
    "سابعة"
  ]
};

var getfaculity = name => {
  var specializations = [];
  //Get All Faculities
  if (name == "") {
    sp.faculties.forEach(element => {
      var item = {};
      item.name = element.name;
      item.id = element.id;
      specializations.push(item);
    });
    return { name: "الكلية", specializations };
  }
  //Get specializations
  else {
    var id = name;
    if (!isNaN(parseInt(id.charAt(0), 10))) {
      //selected year
      var year = parseInt(id.charAt(0), 10);
      id = id.substring(1);

      var faculity = sp.faculties.find(
        element => element.id == id.split("_")[0]
      );
      if (!faculity) {
        return { Error: "No Facutity with this prefix" };
      }
      var checkyear = [];
      for (
        var yearcounter = faculity.startYear;
        yearcounter <= faculity.endYear;
        yearcounter++
      ) {
        var item = yearcounter;
        checkyear.push(item);
      }
      var currentyear = checkyear.indexOf(year) + 1; //starts from 1
      if (currentyear == 0) {
        return { Error: "This year isn't in that faculity" };
      }
      var a = faculity;
      var syears = a.years;
      if (isNaN(parseInt(syears, 10))) {
        syears = 0;
      }
      currentyear = currentyear - syears;

      for (var j = 1; j < id.split("_").length; j++) {
        a = a.specializations.find(element => element.id == id.split("_")[j]);
        if (!a) {
          return { Error: "Can't fint this sp" };
        }
        syears = a.years;
        if (isNaN(parseInt(syears, 10))) {
          syears = 1;
        }
        currentyear = currentyear - syears;
      }
      if (currentyear <= 0) {
        return { Done: true };
      }
      if (a.specializations) {
        a.specializations.forEach(element => {
          var item = {};
          item.name = element.name;
          item.id = year + id + "_" + element.id;
          if (element.yearsonly) {
            var index = element.yearsonly.findIndex(t => t == year);
            if (index != -1) {
              specializations.push(item);
            }
          } else {
            specializations.push(item);
          }
        });
        return { name: a.spname, specializations };
      } else {
        return { Done: true };
      }
    } else {
      //haven't selected year yet
      var selectedfaculity = sp.faculties.find(element => element.id == id);
      if (!selectedfaculity) {
        return { Error: "No Facutity with this prefix" };
      }
      for (
        var i = selectedfaculity.startYear;
        i <= selectedfaculity.endYear;
        i++
      ) {
        var newyearitem = {};
        newyearitem.id = i + selectedfaculity.id;
        if (i > selectedfaculity.credit) {
          newyearitem.name = grades.noncredit[i];
        } else {
          newyearitem.name = grades.credit[i];
        }
        specializations.push(newyearitem);
      }
      return { name: "الفرقة", specializations };
    }
  }
};

const getstring = id => {
  const yearnum = id.charAt(0);
  const year = grades.numbers[yearnum];
  const faculityid = id.substring(1).split("_")[0];
  const faculity = sp.faculties.filter(val => val.id == faculityid)[0].name;
  return { year, faculity };
};

module.exports = { getfaculity, getstring };
